import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import OctoIntro from "../components/octoIntro"
import { useState, useEffect } from "react"

const IndexPage = ({ location, data }) => {
  const [octoShow, setOctoShow] = useState(true);
  const handleOcto = () => setOctoShow(false); 

  useEffect(() => {

    // window.addEventListener("scroll", handleOcto);
    // window.addEventListener("touchstart", handleOcto);


    return () => {
      // window.removeEventListener("scroll", handleOcto);
    };

  }, []); 

  return (
    <Layout scheme="light" location={location} octoShow={octoShow} pageCategory="work">
      <Seo title="Judge the Work" />
      

    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  {
    sanityWorkPage {
      featuredWork {
        id
        slug {
          current
        }
        title
        hoverFileType
        hoverImage {
          altText
           image {
             ...ImageWithPreview
           }
        }
        mobileImage {
          altText
          image {
            ...ImageWithPreview
          }
        }
      }
    }
  }
`